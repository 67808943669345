@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@500&display=swap');

:root {
  --header-height: 7rem;
  --header-height-mobile: 4rem;
  --configurator-banner-height: 2rem;
  --configurator-banner-height-mobile: 2.5rem;
  --body-height: calc(100vh - var(--header-height) - var(--configurator-banner-height));
  --body-height-mobile: calc(100vh - var(--header-height-mobile));
  --text-color: #333333;
  --primary: #BC3908;
  --primary-light: #F76D3B;
  --bg-color: #FFFAFA;
  --pure-white: #FFFFFF;
}

body {
  margin: 0;
  color: var(--text-color);
  background: var(--bg-color);
  font-family: 'Raleway', sans-serif;

  -ms-overflow-style: none;
  scrollbar-width: none;
}

::-webkit-scrollbar {
  display: none;
}

p {
  font-size: 1rem;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
